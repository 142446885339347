const config = {
  apiGateway: {
    REGION: 'us-east-1',
    INTERNAL_API_URL: 'https://edn6ax0rfg.execute-api.us-east-1.amazonaws.com/prod',
    TEAM_API_URL: 'https://cyzuo97il4.execute-api.us-east-1.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.9.2',
    HOSTNAME: 'https://teams-service.staging.riotinto.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.riotinto.forwoodsafety.com',
    WEBSOCKET: 'wss://0ed2rb09ge.execute-api.us-east-1.amazonaws.com/staging'
  },
};

export default config;
